import { Box, Heading, Text } from '@chakra-ui/react';

const ContactPage = () => {
  return (
    <Box maxW="1200px" margin="auto" padding="4">
      <Heading as="h2" size="xl">Contact Us</Heading>
      <Text mt="4">You can reach us at <a href="mailto:your-email@example.com">your-email@example.com</a></Text>
      {/* Add more contact information or a form as needed */}
    </Box>
  );
};

export default ContactPage;
