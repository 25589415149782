import { SimpleGrid, Image, Box } from '@chakra-ui/react';

const LandingPage = () => {
  const images = [
    { src: '/path/to/image1.jpg', alt: 'Image 1' },
    { src: '/path/to/image2.jpg', alt: 'Image 2' },
    // Add more images as needed
  ];

  return (
    <Box padding="4" maxW="1200px" margin="auto">
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="4">
        {images.map((image, index) => (
          <Image key={index} src={image.src} alt={image.alt} borderRadius="md" />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default LandingPage;
