import { Box } from '@chakra-ui/react';

const PrintsPage = () => {

  return (
    <Box padding="4" maxW="1200px" margin="auto">
      <Box> Prints with Paypal </Box>
    </Box>
  );
};

export default PrintsPage;
