import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Box, Container, Flex, Spacer, Text } from '@chakra-ui/react';
import LandingPage from './LandingPage';
import ContactPage from './ContactPage';
import ProyectosPage from './ProyectosPage';
import PrintsPage from './PrintsPage';

const App = () => {
  return (
    <Router>
      <Container maxW="container.xl">
        <Flex as="nav" align="center" justify="space-between" wrap="wrap" padding="1.5rem" bg="teal.500" color="white">
          <Text as="h1" fontSize="xl" fontWeight="bold">My Website</Text>
          <Spacer />
          <Box display="flex" gap="4">
          <Link to="/">Fotografia</Link>
          <Link to="/proyectos">Proyectos</Link>
          <Link to="/prints">Prints</Link>
            <Link to="/contact">Contacto</Link>
          </Box>
        </Flex>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/proyectos">
            <ProyectosPage />
          </Route>
          <Route path="/prints">
            <PrintsPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}

export default App;
